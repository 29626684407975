<template>
  <layout selectedNav="data-source">
    <div class="page-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'DataSource' }"
          >数据来源</el-breadcrumb-item
        >
        <el-breadcrumb-item>数据详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="page-content__list-wrapper" v-loading="showLoading">
        <div class="page-content__list-header">
          <div class="page-content__list-title">{{ form.title }}</div>
          <div class="page-content__tool-btns">
            <el-button type="text">
              <img src="@/assets/images/share.png" alt="分享" />
              <span>分享</span>
            </el-button>
            <el-button type="text">
              <img src="@/assets/images/collect.png" alt="收藏" />
              <span>收藏</span>
            </el-button>
            <el-button type="text">
              <img src="@/assets/images/edit.png" alt="纠错" />
              <span>纠错</span>
            </el-button>
            <el-button type="text">
              <img src="@/assets/images/download-d.png" alt="申请" />
              <span>申请</span>
            </el-button>
          </div>
        </div>
        <div class="page-content__list">
          <div
            class="page-content__item"
            v-for="(item, index) in form.items"
            :key="index"
          >
            <div class="page-content__item-title">
              <span class="titleContent">{{ item.title }}</span>
            </div>
            <div class="page-content__item-content" v-if="item.content">
              {{ item.content }}
            </div>
            <div class="page-content__item-list" v-if="item.list">
              <div
                class="download-item"
                v-for="(downloadItem, index) in item.list"
                :key="index"
              >
                <el-tag :class="`tag-${downloadItem.type}`">{{
                  downloadItem.type
                }}</el-tag>
                <div class="download-item__title">{{ downloadItem.title }}</div>
                <el-button
                  :disabled="bloom == '有条件开放'"
                  type="text"
                  class="download-item__download-link"
                  @click="handleDownloadClick(downloadItem)"
                >
                  <img :src="bloom == '有条件开放' ? imgUrl_g : imgUrl_o" />
                  下载
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel__wrapper">
          <div class="panel__title">使用情况</div>
          <div class="panel__body">
            <div class="panel__item">
              <div class="panel__item-title">下载次数</div>
              <div class="panel__item-value">{{ form.count.download }}次</div>
            </div>
            <div class="panel__item">
              <div class="panel__item-title">浏览次数</div>
              <div class="panel__item-value">{{ form.count.view }}次</div>
            </div>
            <div class="panel__item">
              <div class="panel__item-title">评价次数</div>
              <div class="panel__item-value">{{ form.count.comment }}次</div>
            </div>
          </div>
        </div>
        <div class="commment__wrapper">
          <div class="panel__wrapper">
            <div class="panel__title">用户评分</div>
            <div class="panel__comment__body">
              <div class="panel__item">
                <div class="panel__item-title">综合得分</div>
                <div class="panel__item-value">
                  <el-rate
                    void-color="#56A9FF"
                    :colors="['#56A9FF', '#56A9FF', '#56A9FF']"
                    v-model="form.score.summary"
                  ></el-rate>
                </div>
              </div>
              <div class="panel__item">
                <div class="panel__item-title">准确性</div>
                <div class="panel__item-value">
                  <el-rate
                    void-color="#56A9FF"
                    :colors="['#56A9FF', '#56A9FF', '#56A9FF']"
                    v-model="form.score.accuracy"
                  ></el-rate>
                </div>
              </div>
              <div class="panel__item">
                <div class="panel__item-title">满意度</div>
                <div class="panel__item-value">
                  <el-rate
                    void-color="#56A9FF"
                    :colors="['#56A9FF', '#56A9FF', '#56A9FF']"
                    v-model="form.score.satisfaction"
                  ></el-rate>
                </div>
              </div>
              <div class="panel__item">
                <div class="panel__item-title">及时性</div>
                <div class="panel__item-value">
                  <el-rate
                    void-color="#56A9FF"
                    :colors="['#56A9FF', '#56A9FF', '#56A9FF']"
                    v-model="form.score.timeliness"
                  ></el-rate>
                </div>
              </div>
              <div class="panel__item">
                <div class="panel__item-title">可用性</div>
                <div class="panel__item-value">
                  <el-rate
                    void-color="#56A9FF"
                    :colors="['#56A9FF', '#56A9FF', '#56A9FF']"
                    v-model="form.score.availability"
                  ></el-rate>
                </div>
              </div>
            </div>
          </div>
          <div class="comment__btn-wrapper">
            <el-button type="primary" @click="open">我要评价</el-button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
export default {
  components: {
    Layout
  },
  data() {
    return {
      form: {
        id: null,
        title: "",
        items: [],
        count: {
          download: 0,
          view: 0,
          comment: 0
        },
        score: {
          summary: 0,
          accuracy: 0,
          satisfaction: 0,
          timeliness: 0,
          availability: 0
        }
      },
      bloom: "",
      imgUrl_o: require("@/assets/images/download-o.png"),
      imgUrl_g: require("@/assets/images/download.png"),
      showLoading: false
    };
  },
  methods: {
    getDatasourceDetail() {
      this.showLoading = true;
      this.$api.dataSource
        .detail({
          boid: this.form.id
        })
        .then(data => {
          console.log(data);
          this.showLoading = false;
          if (data.code === 200) {
            this.form.title = data.data[0].title;
            let fileUrls = data.data[0].fileurl;
            this.bloom = data.data[0].bloom;
            this.form.items = [
              {
                title: "摘要",
                content: data.data[0].remark
              },
              {
                title: "应用场景",
                content: data.data[0].scene
              },
              {
                title: "数据领域",
                content: data.data[0].datarealm
              },
              {
                title: "开放属性",
                content: data.data[0].bloom
              },
              {
                title: "资源类型",
                content: data.data[0].type
              },
              {
                title: "更新频率",
                content: data.data[0].updatesize
              },
              {
                title: "发布日期",
                content: data.data[0].createtime
              },
              {
                title: "更新日期",
                content: data.data[0].updatetime
              },
              {
                title: "联系方式",
                content: data.data[0].ipone
              },
              {
                title: "数据来源",
                content: data.data[0].datalaiy
              },
              {
                title: "附件下载",
                list: fileUrls.map(item => {
                  return {
                    fileurl: item.fileurl,
                    title: item.filename,
                    type: item.filename.slice(
                      item.filename.lastIndexOf(".") + 1
                    )
                  };
                })
              }
            ];
            console.log(this.form.items);

            this.form.count = {
              download: data.data[0].downsize,
              view: data.data[0].access,
              comment: 0
            };
          }
        })
        .catch(err => {
          console.log(err);
          this.showLoading = false;
        });
    },
    handleDownloadClick(item) {
      this.$api.dataSource
        .download({
          id: this.form.id
        })
        .then(data => {
          console.log(data);
          this.form.count.download++;
          window.open(item.fileurl);
        });
    },
    open() {
      this.$message({
        message: "提交成功!",
        type: "success"
      });
    }
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.getDatasourceDetail();
  }
};
</script>
<style lang="scss">
.el-button--text {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  font-weight: 600;
  color: #5883f8;
  &:hover {
    color: #5883f8;
  }
}
.el-button.is-disabled {
  img {
    color: #c0c4cc;
  }
}
</style>
<style lang="scss" scoped>
.page-content {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;

  .el-breadcrumb {
    padding-bottom: 15px;

    ::v-deep .el-breadcrumb__inner {
      font-weight: 700;

      &.is-link {
        font-weight: 400;
      }
    }
  }

  .page-content__list-wrapper {
    .page-content__list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      padding: 20px;
    }

    .page-content__list-title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
    }

    .page-content__tool-btns {
      display: inline-flex;
      align-items: center;

      .el-button {
        padding: 0;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;

        &:not(:first-child) {
          margin-left: 25px;
        }

        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

    .page-content__list {
      margin-bottom: 10px;
      .page-content__item {
        display: flex;
        align-items: center;
        min-height: 35px;
        padding-left: 20px;
        background: #ffffff;

        &:nth-child(2n + 1) {
          background: rgba(88, 131, 248, 0.1);
        }

        .page-content__item-title {
          width: 184px;
          display: inline-block;
          vertical-align: middle;
          padding: 8px 0;
          align-self: flex-start;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }

        .page-content__item-content {
          padding-left: 30px;
          width: 996px;
          display: inline-block;
          vertical-align: middle;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
          font-weight: 500;
          color: #656565;
          line-height: 17px;
        }

        .page-content__item-list {
          align-items: flex-start;
          flex: 1;

          .download-item {
            display: flex;
            align-items: center;
            padding-left: 30px;
            border-bottom: 1px solid rgba(88, 131, 248, 0.5);
            height: 40px;

            &:last-child {
              border-bottom: 0;
            }

            .el-tag {
              border: 1px solid #56a9ff;
              background: rgba(86, 169, 255, 0.1);
              color: #4878fa;
              width: 20px;
              border-radius: 2px;
              min-width: 50px;
              font-size: 14px;
              height: 20px;
              line-height: 20px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &.tag-xls {
                border: 1px solid #45b5bd;
                background: rgba(69, 181, 189, 0.1);
                color: #45b5bd;
              }

              &.tag-csv {
                border: 1px solid #484692;
                background: rgba(72, 70, 146, 0.1);
                color: #484692;
              }

              &.tag-xlsx {
              }
            }

            .download-item__title {
              width: 257px;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #333333;
              margin-left: 20px;
            }

            .download-item__download-link {
              margin-left: 200px;
              // font-size: 14px;
              // font-weight: 600;
              // // color: #5883f8;
              // line-height: 20px;
              padding-bottom: 1px;
              padding: 0;
              display: inline-flex;
              align-items: center;

              &:hover {
                // color: #5883f8;
                text-decoration: underline;
              }

              img {
                margin-right: 3px;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .commment__wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .panel__wrapper {
        flex: 1;
      }

      .comment__btn-wrapper {
        margin-left: 10px;
        background: #ffffff;
        padding: 30px 20px;

        .el-button {
          background: #5883f8;
          border-color: #5883f8;
          height: 40px;
          width: 120px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.panel__wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  .panel__title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 20px;
    padding: 7px 20px 8px 20px;
    border-bottom: 1px solid rgba(88, 131, 248, 0.5);
  }
  .panel__body {
    padding: 10px 696px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .panel__item {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      // flex: 1;
      .panel__item-title {
        margin-bottom: 5px;
      }
    }
  }
  .panel__comment__body {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .panel__item {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      flex: 1;

      .panel__item-title {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
